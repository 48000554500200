export default {
  data() {
    return {
      scripts: []
    };
  },
  watch: {},
  methods: {
    getScripts() {
      this.loading = true;
      this.getScriptsFromApi().then(data => {
        this.scripts = data.items;
      });
    },
    getScriptsFromApi() {
      return new Promise(resolve => {
        let items;
        fetch(`${this.serverURL}/scriptDetail/combo`, this.configureFetchInit())
          .then(this.handleRestErrors)
          .then(json => {
            items = json ? json : [];

            setTimeout(() => {
              this.loading = false;
              resolve({
                items
              });
            }, this.timeout);
          })
          .catch(this.handleErrors);
      });
    },
    getScriptNameFromId(id) {
      const et = this.scripts.find(function(e) {
        return e.value == id;
      });

      return et?.text || "";
    }
  },
  mounted() {
    this.getScripts();
  }
};
