export default {
  data() {
    return {
      jobRoles: []
    };
  },
  watch: {},
  methods: {
    getJobRoles() {
      this.loading = true;
      this.getJobRolesFromApi().then(data => {
        this.jobRoles = data.items;
      });
    },
    getJobRolesFromApi() {
      return new Promise(resolve => {
        let items;
        fetch(`${this.serverURL}/jobRole/combo`, this.configureFetchInit())
          .then(this.handleRestErrors)
          .then(json => {
            items = json ? json : [];

            setTimeout(() => {
              this.loading = false;
              resolve({
                items
              });
            }, this.timeout);
          })
          .catch(this.handleErrors);
      });
    },
    getJobRoleNameFromId(id) {
      const et = this.jobRoles.find(function(e) {
        return e.value == id;
      });
      return et?.text || "";
    }
  },
  mounted() {
    this.getJobRoles();
  }
};
