import Vue from "vue";
import Router from "vue-router";
import EventDetailShow from "@/components/eventDetail/EventDetailShow";
import EventDetailList from "@/components/eventDetail/EventDetailList";
import EventTypeList from "@/components/eventType/EventTypeList";
import EventTypeEdit from "@/components/eventType/EventTypeEdit";
import EventTypeCreate from "@/components/eventType/EventTypeCreate";
import ScriptDetailList from "../components/scriptDetail/ScriptDetailList";
import ScriptDetailCreate from "../components/scriptDetail/ScriptDetailCreate";
import ScriptDetailEdit from "../components/scriptDetail/ScriptDetailEdit";
import ChannelList from "@/components/channel/ChannelList";
import ChannelEdit from "@/components/channel/ChannelEdit";
import ChannelCreate from "@/components/channel/ChannelCreate";
import UserList from "@/components/user/UserList";
import UserEdit from "@/components/user/UserEdit";
import UserCreate from "@/components/user/UserCreate";
import JobRoleList from "@/components/jobRole/JobRoleList";
import JobRoleEdit from "@/components/jobRole/JobRoleEdit";
import JobRoleCreate from "@/components/jobRole/JobRoleCreate";
import JobRoleEditSlot from "@/components/jobRole/JobRoleEditSlot";
import HolidaysCreate from "@/components/holidays/HolidaysCreate";
import HolidaysEdit from "@/components/holidays/HolidaysEdit";
import HolidaysList from "@/components/holidays/HolidaysList";

Vue.use(Router);

export default new Router({
  routes: [
    { path: "/", redirect: { name: "EventDetailList" } },
    {
      path: "/event-detail",
      name: "EventDetailList",
      component: EventDetailList,
      meta: {
        title: "Eventi Ricevuti"
      }
    },
    {
      path: "/event-detail/show/:id",
      name: "EventDetailShow",
      component: EventDetailShow
    },
    {
      path: "/event-type",
      name: "EventTypeList",
      component: EventTypeList,
      meta: {
        title: "Tipi Eventi"
      }
    },
    {
      path: "/event-type/edit/:id",
      name: "EventTypeEdit",
      component: EventTypeEdit
    },
    {
      path: "/event-type/create",
      name: "EventTypeCreate",
      component: EventTypeCreate,
      meta: {
        title: "Crea Tipo Evento"
      }
    },
    {
      path: "/script-detail",
      name: "ScriptDetailList",
      component: ScriptDetailList,
      meta: {
        title: "Lista script"
      }
    },
    {
      path: "/script-detail/edit/:id",
      name: "ScriptDetailEdit",
      component: ScriptDetailEdit
    },
    {
      path: "/script-detail/create",
      name: "ScriptDetailCreate",
      component: ScriptDetailCreate,
      meta: {
        title: "Nuovo Script"
      }
    },
    {
      path: "/channel",
      name: "ChannelList",
      component: ChannelList,
      meta: {
        title: "Lista canali"
      }
    },
    {
      path: "/channel/edit/:id",
      name: "ChannelEdit",
      component: ChannelEdit
    },
    {
      path: "/channel/create",
      name: "ChannelCreate",
      component: ChannelCreate,
      meta: {
        title: "Nuovo canale"
      }
    },
    {
      path: "/user",
      name: "UserList",
      component: UserList,
      meta: {
        title: "Lista utenti"
      }
    },
    {
      path: "/user/edit/:id",
      name: "UserEdit",
      component: UserEdit
    },
    {
      path: "/user/create",
      name: "UserCreate",
      component: UserCreate,
      meta: {
        title: "Nuovo utente"
      }
    },
    {
      path: "/job-role",
      name: "JobRoleList",
      component: JobRoleList,
      meta: {
        title: "Lista ruoli"
      }
    },
    {
      path: "/job-role/edit/:id",
      name: "JobRoleEdit",
      component: JobRoleEdit
    },
    {
      path: "/job-role/create",
      name: "JobRoleCreate",
      component: JobRoleCreate,
      meta: {
        title: "Nuovo ruolo"
      }
    },
    {
      path: "/job-role/slot/:id",
      name: "JobRoleEditSlot",
      component: JobRoleEditSlot
    },
    {
      path: "/holidays",
      name: "HolidaysList",
      component: HolidaysList,
      meta: {
        title: "Lista festività"
      }
    },
    {
      path: "/holidays/edit/:id",
      name: "HolidaysEdit",
      component: HolidaysEdit
    },
    {
      path: "/holidays/create",
      name: "HolidaysCreate",
      component: HolidaysCreate,
      meta: {
        title: "Nuovo festività"
      }
    }
  ]
});
