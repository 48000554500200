export default {
  data() {
    return {
      postSaveRouteName: "",
      errors: null,
      item: {},
      isDirty: false
    };
  },
  watch: {},
  methods: {
    setTitle() {},
    submitData() {
      this.$refs.observer.validate().then(valid => {
        if (valid) {
          this.loading = true;
          this.postDataToApi().then(data => {
            this.isDirty = false;
            this.$router.push({ name: this.postSaveRouteName });
            this.item = data.item;
            this.setTitle();
          });
        }
      });
    },
    buildUrl() {
      return `${this.serverURL}/${this.serverPath}`;
    },
    postDataToApi() {
      return new Promise(resolve => {
        let item;
        fetch(
          this.buildUrl(),
          this.configureFetchInit({
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(this.item)
          })
        )
          .then(this.handleRestErrors)
          .then(json => {
            item = json.entity;

            setTimeout(() => {
              this.loading = false;
              resolve({
                item
              });
            }, this.timeout);
          })
          .catch(this.handleErrors);
      });
    }
  }
};
