export default {
  data() {
    return {
      currentJobRoles: []
    };
  },
  watch: {
    currentJobRoles(val) {
      let _this = this;
      this.item.jobRoles = [];
      val.forEach(function(e) {
        _this.item.jobRoles.push({ id: e });
      });
    }
  },
  methods: {
    mapData() {
      let _this = this;
      this.item.jobRoles.forEach(function(e) {
        _this.currentJobRoles.push(e.id);
      });
    }
  }
};
