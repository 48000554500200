import moment from "moment-timezone";

export default {
  data() {
    return {
      dateFrom: null,
      dateTo: null,
      timeFrom: null,
      timeTo: null,
      dateTimeFromDialog: false,
      dateTimeToDialog: false
    };
  },
  watch: {
    dateFrom: {
      handler() {
        this.$set(this.filters, "dateFrom", this.dateTimeFrom);
      },
      deep: true
    },
    dateTo: {
      handler() {
        this.$set(this.filters, "dateTo", this.dateTimeTo);
      },
      deep: true
    },
    timeFrom: {
      handler() {
        this.$set(this.filters, "dateFrom", this.dateTimeFrom);
      },
      deep: true
    },
    timeTo: {
      handler() {
        this.$set(this.filters, "dateTo", this.dateTimeTo);
      },
      deep: true
    }
  },
  computed: {
    today() {
      return moment().format("YYYY-MM-DD");
    },
    dateTimeFrom() {
      return this.formatDateTimeUTC(this.dateFrom, this.timeFrom);
    },
    dateTimeTo() {
      return this.formatDateTimeUTC(this.dateTo, this.timeTo);
    },
    maxDateFrom() {
      if (!this.dateTimeTo) {
        return this.today;
      } else {
        return moment(String(this.dateTimeTo)).format("YYYY-MM-DD");
      }
    },
    maxTimeFrom() {
      if (
        !this.dateTimeTo &&
        (this.dateFrom == this.today || this.dateFrom == null)
      ) {
        return moment().format("HH:mm:ss");
      } else if (this.dateTo == this.dateFrom) {
        return moment(String(this.dateTimeTo)).format("HH:mm:ss");
      }
    },
    maxDateTo() {
      return this.today;
    },
    maxTimeTo() {
      return moment().format("HH:mm:ss");
    },
    minDateTo() {
      if (this.dateTimeFrom) {
        return moment(String(this.dateTimeFrom)).format("YYYY-MM-DD");
      }
    },
    minTimeTo() {
      if (this.dateTimeFrom) {
        return moment(String(this.dateTimeFrom)).format("HH:mm:ss");
      }
    }
  },
  methods: {
    lastSeconds(sec) {
      this.timeTo = null;
      this.dateTo = null;
      const secago = moment().subtract(sec, "seconds");
      this.timeFrom = secago.format("HH:mm:ss");
      this.dateFrom = secago.format("YYYY-MM-DD");
    },
    resetTime() {
      this.timeTo = null;
      this.dateTo = null;
      this.timeFrom = null;
      this.dateFrom = null;
    }
  }
};
