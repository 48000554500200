export default {
  data() {
    return {
      filters: {},
      loading: true,
      total: 0,
      options: {},
      items: []
    };
  },
  watch: {
    options: {
      handler() {
        this.loadData();
      },
      deep: true
    },
    filters: {
      handler() {
        this.loadData();
      },
      deep: true
    }
  },
  methods: {
    loadData() {
      this.loading = true;
      this.getDataFromApi().then(data => {
        this.items = data.items;
        this.total = data.total;
      });
    },
    buildUrl(itemsPerPage, page, sortBy, sortDesc) {
      let _this = this;
      let url = this.buildPaginationUrl(
        `${this.serverURL}/${this.serverPath}/query`,
        itemsPerPage,
        page,
        sortBy,
        sortDesc
      );
      if (this.filters) {
        Object.keys(this.filters).forEach(function(key) {
          if (_this.filters[key]) {
            url += `&${key.replace("_", ".")}=${_this.filters[key]}`;
          }
        });
      }
      return url;
    },
    getDataFromApi() {
      return new Promise(resolve => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        let items, total;
        fetch(
          this.buildUrl(itemsPerPage, page, sortBy, sortDesc),
          this.configureFetchInit()
        )
          .then(this.handleRestErrors)
          .then(json => {
            items = json.entities ? json.entities : [];
            total =
              json.paging && json.paging.totalCount
                ? json.paging.totalCount
                : 0;

            setTimeout(() => {
              this.loading = false;
              resolve({
                items,
                total
              });
            }, this.timeout);
          })
          .catch(this.handleErrors);
      });
    }
  }
};
