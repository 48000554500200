export default {
  data() {
    return {
      errors: null,
      item: {},
      isDirty: false
    };
  },
  watch: {},
  methods: {
    setTitle() {},
    submitData() {
      this.$refs.observer.validate().then(valid => {
        if (valid) {
          this.loading = true;
          this.putDataToApi(this.$route.params.id).then(data => {
            this.isDirty = false;
            this.item = data.item;
            this.setTitle();
          });
        }
      });
    },
    async deleteConfirm() {
      if (
        await this.$refs.confirm.open(
          "Conferma",
          "Sei sicuro di voler cancellare questo record?"
        )
      ) {
        this.deleteData();
      }
    },
    deleteData() {
      this.loading = true;
      this.deleteDataFromApi(this.$route.params.id).then(() => {
        this.$router.go(-1);
      });
    },
    loadData() {
      return new Promise(resolve => {
        this.loading = true;
        this.getDataFromApi(this.$route.params.id).then(data => {
          this.isDirty = false;
          this.item = data.item;
          this.setTitle();
          resolve(true);
        });
      });
    },
    buildUrl(id) {
      return `${this.serverURL}/${this.serverPath}/${id}`;
    },
    deleteDataFromApi(id) {
      return new Promise(resolve => {
        fetch(
          this.buildUrl(id),
          this.configureFetchInit({
            method: "DELETE",
            headers: {
              "Content-Type": "application/json"
            }
          })
        )
          .then(this.handleDeleteRestErrors)
          .then(() => {
            setTimeout(() => {
              this.loading = false;
              resolve({});
            }, this.timeout);
          })
          .catch(this.handleErrors);
      });
    },
    putDataToApi(id) {
      return new Promise(resolve => {
        let item;
        fetch(
          this.buildUrl(id),
          this.configureFetchInit({
            method: "PUT",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(this.item)
          })
        )
          .then(this.handleRestErrors)
          .then(json => {
            item = json.entity;
            setTimeout(() => {
              this.loading = false;
              resolve({
                item
              });
            }, this.timeout);
          })
          .catch(this.handleErrors);
      });
    },
    getDataFromApi(id) {
      return new Promise(resolve => {
        let item;
        fetch(this.buildUrl(id), this.configureFetchInit())
          .then(this.handleRestErrors)
          .then(json => {
            item = json.entity;
            setTimeout(() => {
              this.loading = false;
              resolve({
                item
              });
            }, this.timeout);
          })
          .catch(this.handleErrors);
      });
    }
  }
};
