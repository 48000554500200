import moment from "moment-timezone";
import resolveEnv from "../utils/resolveEnv";

export default {
  props: {
    keycloak: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      serverURL: resolveEnv("VUE_APP_SERVER_URL"),
      timeout: resolveEnv("VUE_APP_API_TIMEOUT"),
      app: {
        info: {
          be: {},
          fe: {
            version: resolveEnv("VUE_APP_VERSION")
          }
        }
      }
    };
  },
  watch: {},
  methods: {
    handleGrailEmbeddedError(json) {
      let message = json.hasOwnProperty("message") && json.message;
      if (
        json.hasOwnProperty("_embedded") &&
        json._embedded.hasOwnProperty("errors")
      )
        message = json._embedded.errors
          .map(function(item) {
            return item.message;
          })
          .join(", ");
      return message;
    },
    handleRestErrors(response) {
      return response.json().then(json => {
        if (response.ok) {
          return json;
        } else {
          if (response.status === 401) {
            // this.keycloak.logout()
          }
          const statusText = this.handleGrailEmbeddedError(json);
          return Promise.reject(
            Object.assign(
              {},
              { json },
              {
                status: response.status,
                statusText: response.statusText
              },
              { statusText }
            )
          );
        }
      });
    },
    handleErrors(error) {
      // eslint-disable-next-line no-console
      console.error(error);
      let title = error.status;
      let message = `${error.status} - ${error.statusText}`;
      if (!error.status && !error.statusText && !error.response) {
        title = "Network error";
        message = `${title} - ${error}`;
      }

      this.$emit("show-error", {
        title,
        message
      });
      this.loading = false;
    },
    handleDeleteRestErrors(response) {
      if (response.ok) {
        return response;
      } else {
        // eslint-disable-next-line no-console
        console.error("error");
        // eslint-disable-next-line no-console
        console.error(response);
        if (response.status === 401) {
          // this.keycloak.logout()
        }
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return this.handleRestErrors(response);
        } else {
          return Promise.reject(
            Object.assign(
              {},
              {
                status: response.status,
                statusText: response.statusText
              }
            )
          );
        }
      }
    },
    configureFetchInit(object) {
      object = object || {};
      if (!object.hasOwnProperty("headers")) {
        object.headers = {};
      }
      Object.assign(object.headers, {
        Authorization: `Bearer ${this.keycloak.token}`
      });
      return object;
    },
    goBackward() {
      this.$router.go(-1);
    },
    goForward() {
      this.$router.go(1);
    },
    buildPaginationUrl(url, itemsPerPage, page, sortBy, sortDesc) {
      const offset = (page - 1) * itemsPerPage;
      url += `?max=${itemsPerPage}&offset=${offset}`;
      if (sortBy.length && sortDesc.length) {
        url += `&sort=${sortBy}&order=${sortDesc[0] == false ? "asc" : "desc"}`;
      }
      return url;
    },
    formatDateTimeUTC(date, time) {
      if (!date) return null;

      let datetime = date + "T" + (time || "00:00:00");
      return (
        moment.utc(moment(String(datetime))).format("YYYY-MM-DDTHH:mm:ss") + "Z"
      );
    },
    formatLocalDateTime(date, time) {
      if (!date) return null;

      let datetime = date + "T" + (time || "00:00:00");
      return moment(String(datetime)).format("YYYY-MM-DDTHH:mm:ss.SSS");
    },
    nameOfMonthAndYear(date) {
      return moment(date).format("MMMM yyyy");
    }
  },
  mounted() {}
};
