export default {
  data() {
    return {
      users: []
    };
  },
  watch: {},
  methods: {
    getUsersFromApi() {
      return new Promise(resolve => {
        let items;
        fetch(`${this.serverURL}/user/combo`, this.configureFetchInit())
          .then(this.handleRestErrors)
          .then(json => {
            items = json ? json : [];
            setTimeout(() => {
              this.loading = false;
              resolve({
                items
              });
            }, this.timeout);
          })
          .catch(this.handleErrors);
      });
    },
    filterUser(item, queryText) {
      return item.text.toLowerCase().indexOf(queryText.toLowerCase()) > -1;
    },
    getUsers() {
      this.getUsersFromApi().then(data => {
        this.users = data.items;
      });
    },
    getDefaultUserNameFromId(id) {
      const et = this.users.find(function(e) {
        return e.value == id;
      });

      return et?.text || "";
    },
    editDefaultUserItem(oid) {
      this.$router.push({ name: "UserEdit", params: { id: oid } });
    }
  },
  mounted() {
    this.getUsers();
  }
};
