import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
import "./assets/css/custom.scss";

import vuetify from "./plugins/vuetify";
import moment from "moment";
import * as Keycloak from "keycloak-js";
import resolveEnv from "./utils/resolveEnv";

moment.locale("it");

Vue.filter("formatDateTime", function(value) {
  if (value) {
    if (typeof value != "number") {
      value = String(value);
    }
    return moment(value).format("DD/MM/YYYY HH:mm:ss");
  }
});

Vue.filter("formatDateTimeNoSecond", function(value) {
  if (value) {
    if (typeof value != "number") {
      value = String(value);
    }
    return moment(value).format("DD/MM/YYYY HH:mm");
  }
});

Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY");
  }
});

Vue.filter("truncate", function(text, length, clamp, direction) {
  clamp = clamp || "...";
  direction = direction || "start";
  var node = document.createElement("div");
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length
    ? direction === "start"
      ? content.slice(0, length) + clamp
      : clamp + content.slice(content.length - length, content.length)
    : content;
});

let initOptions = {
  url: resolveEnv("VUE_APP_KEYCLOAK_URL"),
  realm: resolveEnv("VUE_APP_KEYCLOAK_REALM"),
  clientId: resolveEnv("VUE_APP_KEYCLOAK_CLIENT_ID"),
  onLoad: "login-required"
};

Vue.config.productionTip = false;

let keycloak = Keycloak(initOptions);

keycloak
  .init({ onLoad: initOptions.onLoad })
  .then(auth => {
    if (!auth) {
      window.location.reload();
    } else {
      // c0nsole.info("Authenticated");

      /* eslint-disable no-new */
      new Vue({
        el: "#app",
        router,
        components: { App },
        vuetify,
        render: h => h(App, { props: { keycloak } })
      });
    }
    //Token Refresh
    setInterval(() => {
      keycloak
        .updateToken(70)
        .then(refreshed => {
          if (refreshed) {
            // console.info("Token refreshed" + refreshed);
          } else {
            // c0nsole.log(keycloak.token);
            /*
            c0nsole.warn(
              "Token not refreshed, valid for " +
                Math.round(
                  keycloak.tokenParsed.exp +
                    keycloak.timeSkew -
                    new Date().getTime() / 1000
                ) +
                " seconds"
            );
                         */
          }
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.error("Failed to refresh token");
        });
    }, 6000);
  })
  .catch(() => {
    // eslint-disable-next-line no-console
    console.error("Authenticated Failed");
  });
